<template>
    <div>
      <div class="a">
        <el-input v-model="searchInfo" placeholder="请输入点位编号或资源名" style="width: 200px;"></el-input>
        <el-button type="primary" style="margin-left:5px" @click="Search"> 查询</el-button>
      </div>
      
        <el-table :data='MediaListData' size="mini" ref='mediaTable' stripe :highlight-current-row='true' style="margin-top: 1rem;">
          <el-table-column v-if="orderId>0" align="center" label="订单">
            <template>
              {{orderName}}
            </template>
          </el-table-column>
            <el-table-column  align="center" prop="media_no" label="点位编号"></el-table-column>
            <el-table-column align="center" prop="media_name" label="名称"></el-table-column>
            <el-table-column align="center" prop="media_show_type" label="点位类型">
              <template slot-scope="slot">
                {{slot.row.media_show_type===0?'灯箱广告':(slot.row.media_show_type===1?'道闸广告':(slot.row.media_show_type===2?'电梯框架':(
                  slot.row.media_show_type===3?'广告看板':(slot.row.media_show_type===4?'屏蔽门':(slot.row.media_show_type===5?'候车亭广告':(
                    slot.row.media_show_type===6?'车身广告':'其他'))))))}}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="media_community_addr" label="资源地址"></el-table-column>
            <el-table-column align="center" prop="media_addr" label="点位地址"></el-table-column>
            <el-table-column align="center" prop="media_remark" label="备注"></el-table-column>
            <el-table-column align="center" prop="log_state" label="点位状态">
              <template slot-scope="slot">
                  {{slot.row.log_state===0?'选定中':(slot.row.log_state===1?'已结束':'被占用')}}
              </template>
            </el-table-column>
            <el-table-column v-if="showDel" align="center" label="操作">
                <template slot-scope="slot">
                    <el-link type="primary" @click="Del(slot.row)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import util from '../../util/util'

export default {
    data: function () {
       return { 
          DataList:[],
          MediaListData:[],
          searchInfo:''
       }
    },
    props: {
      orderName:String,
      orderId:Number,
      showDel:Boolean
    },
    created () {
        this.GetDataList()
    },
    methods: {
      GetDataList(){
        util.Get('publish/getorderdetail?id=' + this.orderId).then(res => {
          if (res.rpStatus === 10000) {
            this.DataList=res.list
            this.MediaListData = res.list
          }
        })
      },
      Del(row){
        this.$confirm('确定删除点位“' + row.media_name + '”？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            util.Get("applyorder/del?orderId="+this.orderId+"&mediaId="+row.ms_id).then(res=>{
              if(res.rpStatus===10000){
                this.GetDataList()
              }
            })
          }).catch(e => e)
        },
      Search(){
          this.MediaListData=this.DataList
          if(this.searchInfo){
            let list=JSON.parse(JSON.stringify(this.DataList))
            this.MediaListData= list.filter(a=>(a.media_name!=null&&a.media_name.indexOf(this.searchInfo)!==-1)||(a.ms_no!=null&& a.ms_no.indexOf(this.searchInfo)!==-1))
          }
        }
    },
   
  }

</script>


<style scoped>
.a{
  display: flex;
  flex-direction: row;
  justify-content: start;
}
</style>